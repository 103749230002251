<template>
  <div>
    <h2>Evaluations</h2>
    <div class="row">
      <div class="col-sm-4">
        <div class="card">
          <div class="card-header">
            <h3 class="cart-title">
              <a data-action="collapse">Host evaluation</a>
            </h3>
          </div>
          <div class="card-content collapse show">
            <div class="card-body">
              <table
                v-if="promoEvaluations.host"
                class="table-bordered table table-sm"
              >
                <tr>
                  <td style="border-right: 1px solid #ebe9f1">
                    <strong>{{ promoEvaluations.host.evaluable.true_name }}</strong>
                  </td>
                  <td class="text-end">
                    <span
                      v-if="promoEvaluations.host && promoEvaluations.host.id"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="View host evaluation in this page"
                    >
                      <a
                        class="btn btn-icon btn-flat-secondary"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasHostEvaluation"
                        aria-controls="offcanvasHostEvaluation"
                        @click="openCanva('Host', promoEvaluations.host, 1)"
                      >
                        View evaluation
                      </a>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h3 class="cart-title">
              <a data-action="collapse">Promotion Commitee evaluations</a>
            </h3>
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li>
                  <a data-action="collapse"><i data-feather="chevron-down" /></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-content collapse show">
            <div class="card-body">
              <table
                v-if="promoEvaluations.pcMembers"
                class="table-bordered table table-sm"
              >
                <tr
                  v-for="member in promoEvaluations.pcMembers"
                  :key="member.id"
                >
                  <td style="border-right: 1px solid #ebe9f1">
                    <strong>{{ member.name }}</strong>
                    <br>
                    <small>{{ member.position }}</small>
                  </td>
                  <td
                    v-if="member.grade"
                    style="border-right: 1px solid #ebe9f1"
                    v-html="member.grade"
                  />
                  <td
                    v-else
                    style="border-right: 1px solid #ebe9f1"
                  >
                    <span class="badge bg-danger">None</span>
                  </td>

                  <td
                    class="text-end"
                  >
                    <span
                      v-if="member.grade"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="View PC Member evaluation in this page"
                    >
                      <a
                        class="btn btn-icon btn-flat-secondary"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasPCMemberEvaluation"
                        aria-controls="offcanvasPCMemberEvaluation"
                        @click="openCanva('PC Member', member, 2)"
                      >
                        View evaluation
                      </a>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="card">
          <div class="card-header">
            <h3 class="cart-title">
              <a data-action="collapse">Peers</a>
            </h3>
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li>
                  <a data-action="collapse"><i data-feather="chevron-down" /></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-content collapse show">
            <div class="card-body">
              <table class="table-bordered table table-sm">
                <tr
                  v-for="member in promoEvaluations.peers"
                  :key="member.id"
                >
                  <td style="border-right: 1px solid #ebe9f1">
                    <strong>{{ member.name }}</strong> <span class="badge bg-light-success">Proposed by: {{ loggedUser.roles.includes('pc-member') && member.proposed_by && member.proposed_by.pivot.added_by == 'Researcher' ? 'ICREA' : (member.proposed_by ? member.proposed_by.pivot.added_by : '-') }}</span>
                    <br>
                    <small>{{ member.position && member.position.id ? member.position.name : member.position }}</small>
                  </td>
                  <td
                    v-if="member.grade"
                    style="border-right: 1px solid #ebe9f1"
                  >
                    <span
                      class="badge bg-primary"
                    >{{ member.grade }}</span>
                  </td>
                  <td
                    v-else
                    style="border-right: 1px solid #ebe9f1"
                    v-html="member.status"
                  />
                  <td class="text-end">
                    <span
                      v-if="member.grade"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="View peer evaluation in this page"
                    >
                      <a
                        class="btn btn-icon btn-flat-secondary"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasPeerEvaluation"
                        aria-controls="offcanvasPeerEvaluation"
                        @click="openCanva('Peer', member, 3)"
                      >View evaluation
                      </a>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ShowPromotionEvaluation
      v-if="selected && selectedTitle && type"
      :title="selectedTitle"
      :data="selected"
      :type="type"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ShowPromotionEvaluation from './view-evaluations/ShowPromotionEvaluation.vue'

export default {
  components: {
    ShowPromotionEvaluation,
  },
  props: {
    userId: { type: Number, required: true },
  },
  data() {
    return {
      selected: {},
      selectedTitle: '',
      type: null,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      promoEvaluations: 'convo/promoEvaluation',
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    await this.$store.dispatch('convo/fetchPromoEvaluation', { convo: this.convo.id, user: this.userId, all: true })
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    openCanva(title, data, type) {
      this.selected = data
      this.selectedTitle = title
      this.type = type
      this.$store.dispatch('modals/toggleShowEvaluation', true)
    },
  },
}
</script>
