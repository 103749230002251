<template>
  <!-- BEGIN: Content-->
  <div
    v-if="user.id && convo.id && !loading"
    class="app-content content "
  >
    <div
      class="content-overlay"
    />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ user.name }} · {{ convo.title }}
              </h2>
            </div>
            <p v-if="loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('pc-member')">
              Date of birth: <strong>{{ user.birthday }} ({{ user.years_old }})</strong>
            </p>
          </div>
        </div>
        <!-- <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              href="evaluation-dashboard.php"
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back"
            ><i data-feather="chevron-left" /></a>
          </div>
        </div> -->
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card">
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <img
                        :src="user.avatarUrl ? user.avatarUrl : require('@/assets/images/avatars/user_default.png')"
                        alt="Antonio Acín"
                        class="img-fluid"
                      >
                    </div>
                    <div class="col-sm-9">
                      <div class="row">
                        <div class="col-sm-6">
                          <h3>Working place</h3>
                          <table class="table table-condensed table-bordered">
                            <tr>
                              <td>Incorporation to ICREA</td>
                              <td>{{ user.start_date }}</td>
                            </tr>
                            <tr>
                              <td>Institution</td>
                              <td>{{ user.hosts[0] ? `${user.hosts[0].name}, ${user.hosts[0].code}` : '-' }}</td>
                            </tr>
                            <template v-if="user.hosts[1]">
                              <tr>
                                <td>Institution 2</td>
                                <td>{{ user.hosts[1] ? `${user.hosts[1].name}, ${user.hosts[1].code}` : '-' }}</td>
                              </tr>
                            </template>
                            <tr>
                              <td>Department</td>
                              <td>{{ user.department_aux && user.department_aux.id ? user.department_aux.name : (user.department_aux ? user.department_aux : '-') }}</td>
                            </tr>
                            <tr>
                              <td>ERC</td>
                              <td>{{ showErcs() }}</td>
                            </tr>
                            <tr>
                              <td>Researcher ID (WoS)</td>
                              <td>{{ user.researcher_id }}</td>
                            </tr>
                            <tr>
                              <td>Author ID (Scopus)</td>
                              <td>{{ user.author_id }}</td>
                            </tr>
                            <tr>
                              <td>ORCID</td>
                              <td>{{ user.orcid }}</td>
                            </tr>
                          </table>
                          <div class="row mt-2">
                            <!-- <div class="col">
                              <h4>Icrea's Personal Page</h4>
                              <a
                                :href="$router.resolve({ name: 'researcher.administrative-data.researcher', params: { id: user.id } }).href"
                                target="_blank"
                                class="btn btn-dark btn-sm"
                              >View personal page</a>
                            </div> -->
                            <div
                              v-if="user.CVFile[0]"
                              class="col"
                            >
                              <h4>Full CV</h4>
                              <a
                                :href="user.CVFile[0].url"
                                target="_blank"
                                class="btn btn-dark btn-sm"
                              >View CV</a>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <h3>Keywords</h3>
                          <span
                            v-for="keyword in user.keywords"
                            :key="keyword.id"
                            class="badge bg-dark me-1 mb-1"
                          >{{ keyword.word }}</span>
                          <hr>
                          <h3>Short biography</h3>
                          <p><small v-html="user.short_biography" /></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h4 class="d-inline me-3">
                        Research Report
                      </h4>
                      <a
                        :href="file_present.url"
                        target="_blank"
                        :disabled="!file_present.url"
                        :class="{'disabled': !file_present.url }"
                        class="btn btn-dark"
                      >Open Research Report</a>
                    </div>
                    <div
                      v-if="loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('pc-member')"
                      class="col"
                    >
                      <h4 class="d-inline me-3">
                        Future Research plan
                      </h4>
                      <a
                        :href="file_future.url"
                        target="_blank"
                        :disabled="!file_future.url"
                        :class="{'disabled': !file_future.url }"
                        class="btn btn-dark"
                      >Open future research plan</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Publications
              v-if="user.id && !loading"
              :user-id="user.id"
              :toggled="false"
              :year-between="yearBetween"
              :promo="convo"
              :field="{label: 'Publications'}"
              @goToIndex="goToIndex"
            />

            <GrantsResource
              v-if="user.id && !loading"
              :user-id="user.id"
              :toggled="false"
              :year-between="yearBetween"
              :promo="convo"
              :field="{label: 'Grants & Resources'}"
              @goToIndex="goToIndex"
            />

            <Conferences
              v-if="user.id && !loading"
              :user-id="user.id"
              :toggled="false"
              :promo="convo"
              :year-between="yearBetween"
              :field="{label: 'Conferences, workshops and invited talks'}"
              @goToIndex="goToIndex"
            />

            <CoursesAndSeminars
              v-if="user.id && !loading"
              :user-id="user.id"
              :toggled="false"
              :promo="convo"
              :year-between="yearBetween"
              :field="{label: 'Courses and Seminars'}"
              @goToIndex="goToIndex"
            />

            <PhDTheses
              v-if="user.id && !loading"
              :user-id="user.id"
              :toggled="false"
              :promo="convo"
              :year-between="yearBetween"
              :field="{label: 'PhD, Master Theses and TFG'}"
              @goToIndex="goToIndex"
            />

            <ManagerialActivities
              v-if="user.id && !loading"
              :user-id="user.id"
              :promo="convo"
              :toggled="false"
              :year-between="yearBetween"
              :field="{label: 'Managerial activities'}"
              @goToIndex="goToIndex"
            />

            <hr>

            <!-- Esta sección sólo admin: inicio -->
            <EvaluationResume
              v-if="loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('pc-member')"
              :user-id="user.id"
            />

            <template v-if="loggedUser.roles.includes('super-admin') && !loading">
              <PromotionHistoryResume />

              <PromotionResult />
            </template>
            <!-- Esta sección sólo admin: final -->

          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->

</template>

<script>
import { mapGetters } from 'vuex'
import 'vue2-datepicker/index.css'
import Vue from 'vue'
import Publications from '../../researcher/scientific-production-report/components/Publications.vue'
import GrantsResource from '../../researcher/scientific-production-report/components/GrantsResource.vue'
import Conferences from '../../researcher/scientific-production-report/components/Conferences.vue'
import CoursesAndSeminars from '../../researcher/scientific-production-report/components/CoursesAndSeminars.vue'
import PhDTheses from '../../researcher/scientific-production-report/components/PhDTheses.vue'
import ManagerialActivities from '../../researcher/scientific-production-report/components/ManagerialActivities.vue'
import EvaluationResume from './evaluations/EvaluationResume.vue'
import PromotionHistoryResume from './evaluations/PromotionHistoryResume.vue'
import PromotionResult from './evaluations/PromotionResult.vue'

export default {
  components: {
    Publications,
    GrantsResource,
    Conferences,
    CoursesAndSeminars,
    PhDTheses,
    ManagerialActivities,
    EvaluationResume,
    PromotionHistoryResume,
    PromotionResult,
  },
  data() {
    return {
      loading: true,
      file_present: {},
      file_future: {},
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      user: 'users/user',
      loggedUser: 'auth/admin',
      histories: 'promotionHistory/items',
    }),
    yearBetween() {
      return {
        from: (this.convo.year - 5),
        to: this.convo.year,
      }
    },
    userId() {
      return this.$route.query.user || this.$route.params.user
    },
    convoId() {
      return this.$route.query.convo || this.$route.params.convo
    },
  },
  async mounted() {
    if (this.userId && this.convoId) {
      await this.$store.dispatch('convo/fetchSpecific', this.convoId)
      await this.$store.dispatch('users/fetchUser', this.userId)
      this.getFiles()
      this.$store.dispatch('promotionHistory/filter', { researcher: this.user })

      this.$store.dispatch('preview/togglePersonalInformation', false)
      this.loading = false
    } else {
      this.$router.push({ name: 'evaluator.calls.evaluations.profile' })
    }
  },
  methods: {
    async getFiles() {
      const files = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/get-files`, { user_id: this.user.id })

      files.data.data.forEach(e => {
        if (e.type == 8) {
          this.file_future = e
        } else {
          this.file_present = e
        }
      })
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name && search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
    async goToIndex(route) {
      let url = ''
      if (Array.isArray(route)) {
        if (route[0] && route[0].id) {
          url = this.$router.resolve({ name: route[1], params: route[0] }).href
        } else {
          url = this.$router.resolve({ name: route[1], query: route[0] }).href
        }
      }
      window.open(url, '_blank')
    },
    showErcs() {
      if (this.user && this.user.main_ercs) {
        if (this.user.main_ercs.level3) {
          return this.user.main_ercs.level3.code
        } if (this.user.main_ercs.level2) {
          return this.user.main_ercs.level1.code
        } if (this.user.main_ercs.level1) {
          return this.user.main_ercs.level1.code
        }
      }
      return '-'
    },
  },
}
</script>
