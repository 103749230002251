<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <!-- offcanvas Repeaters -->
    <div
      id="offcanvasRepeaters"
      class="offcanvas offcanvas-end offcanvas-end--big-panel d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasRepeatersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasRepeatersLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2">
            <i data-feather="user" />
          </span>
          <strong>{{ title }} evaluation</strong>
        </h3>
        <button
          type="button"
          class="btn-close text-reset btn btn-icon btn-icon rounded-circle btn-secondary"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="ms-2">
        <h3>
          Researcher: {{ researcherAux ? researcherAux.full_name : researcher.full_name }} - {{ researcherAux ? (researcherAux.hosts && researcherAux.hosts[0] ? researcherAux.hosts[0].code : null) : (researcher.hosts && researcher.hosts[0] ? researcher.hosts[0].code : null) }}
        </h3>
        <hr>
      </div>
      <div
        v-if="type == 1"
        class="offcanvas-body"
      >
        <h3>{{ data.evaluable.true_name }}</h3>
        <hr>
        <h3 class="mb-2">
          1. Evaluation details
        </h3>
        <div class="ms-1">
          <h4>1.1. Adaptation process in the workplace:</h4>
          <p v-html="data.adaptation_process" />
          <hr>
          <h4>1.2. Relationship with colleagues: </h4>
          <p v-html="data.colleagues_relationship" />
          <hr>
          <h4>1.3. Relationship with students: </h4>
          <p v-html="data.students_relationship" />
          <hr>
          <h4>1.4. Teaching and participation in master or doctoral programmes: </h4>
          <p v-html="data.programmes_participation" />
          <hr>
          <h4>1.5. Schedule and availability: </h4>
          <p v-html="data.schedule" />
          <hr>
          <h4>1.6. Organizational work: </h4>
          <p v-html="data.organizational_work" />
          <hr>
          <h4>1.7 Team leadership:</h4>
          <p v-html="data.team_leadership" />
          <hr>
          <h4>1.8. Participation in the unit's activities: </h4>
          <p v-html="data.activity_participation" />
          <hr>
          <h4>1.9. Comments: </h4>
          <p v-html="data.general_comments" />
          <h4>1.10. The researcher's degree of integration is:</h4>
          <p v-html="data.degree" />
        </div>
        <h3 class="mt-1 mb-2">
          2. Host Institution: additional information
        </h3>
        <div class="row">
          <div class="col-sm-12 ms-1">
            <h4>2.1 Facilities:</h4>
            <p v-html="data.facilities" />
          </div>
          <div class="col-sm-12 ms-1">
            <h4>2.2 Working conditions over the last 3 or 5 years: </h4>
            <p v-html="data.working_conditions" />
          </div>
          <div class="col-sm-12 ms-1">
            <h4>2.3 Opportunities offered by the host to the ICREA researcher: </h4>
            <p v-html="data.offered_oportunities" />
          </div>
        </div>

      </div>
      <div
        v-if="type == 2"
        class="offcanvas-body"
      >
        <h3>{{ data.name }}</h3>
        <p>{{ data.position }}</p>
        <p><strong>Overall Qualification:</strong>
          <strong
            v-if="data.grade > 6"
            class="badge bg-success"
          >{{ data.grade }}</strong>
          <strong
            v-if="data.grade <= 6 && data.grade >= 4 "
            class="badge bg-warning"
          >{{ data.grade }}</strong>
          <strong
            v-if="data.grade < 4 "
            class="badge bg-danger"
          >{{ data.grade }}</strong>
        </p>
        <hr>
        <div class="row">
          <div class="col-sm-4">
            <h4>Research Impact:</h4>
            <p v-html="data.evaluation.research_impact_qualification_span" />
            <p v-html="data.evaluation.research_impact" />
          </div>
          <div class="col-sm-4">
            <h4>Research Leadership:</h4>
            <p v-html="data.evaluation.research_leadership_qualification_span" />
            <p v-html="data.evaluation.research_leadership" />
          </div>
          <div class="col-sm-4">
            <h4>Future Research Plan:</h4>
            <p v-html="data.evaluation.future_research_plan_qualification_span" />
            <p v-html="data.evaluation.future_research_plan" />
          </div>
        </div>

        <hr>

        <h3>General Comments and Recommendations:</h3>
        <p v-html="data.evaluation.overall_comments_qualification_span" />
        <div class="row">
          <div class="col">
            <h4>Positive:</h4>
            <p v-html="data.evaluation.positive_overall_comments" />
          </div>
          <div class="col">
            <h4>To be improved:</h4>
            <p v-html="data.evaluation.negative_overall_comments" />
          </div>
        </div>

      </div>

      <div
        v-if="type == 3"
        class="offcanvas-body"
      >
        <h3>{{ data.name }}</h3>
        <p>{{ data.position && data.position.id ? data.position.name : data.position }}</p>
        <p><span class="badge bg-success">Proposed by: {{ data.proposed_by ? data.proposed_by.pivot.added_by : '-' }}</span></p>
        <p><strong>Overall Qualification:</strong> <strong class="badge bg-success display-5">{{ data.evaluation.peer_grade }}</strong><br><small>Mark from 1 (worst) to 10 (best)</small></p>
        <hr>
        <div class="row">
          <div class="col-sm-6">
            <h4>Research</h4>
            <p v-html="data.evaluation.research_impact" />
          </div>
          <div class="col-sm-6">
            <h4>Leadership</h4>
            <p v-html="data.evaluation.research_leadership" />
          </div>
        </div>

        <hr>

        <h3 class="mb-2">
          General Comments and Recommendations
        </h3>
        <div class="row">
          <div class="col">
            <h4>Positive:</h4>
            <p v-html="data.evaluation.positive_overall_comments" />
          </div>
          <div class="col">
            <h4>To be improved:</h4>
            <p v-html="data.evaluation.negative_overall_comments" />
          </div>
        </div>

      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  props: {
    title: { type: String, required: true },
    data: { type: Object, required: true },
    type: { type: Number, required: true },
    researcherAux: { type: Object, required: false, default: () => {} },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/promotionShowEvaluation',
      researcher: 'users/user',
    }),
  },
  async mounted() {
    feather.replace({
      width: 14,
      height: 14,
    })
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleShowEvaluation', false)
    },
  },
}
</script>
