<template>
  <div class="card card--table">
    <div class="card-header d-flex align-items-center justify-content-between">
      <h3 class="cart-title">
        <a data-action="collapse">Promotion history</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>

    </div>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Year</th>
            <th>Levels</th>
            <th>Evaluation</th>
            <th>Result</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="history in histories"
            :key="history.id"
          >
            <td>{{ history.promotion.year }}</td>
            <td>{{ history.levels }}</td>
            <td v-html="history.evaluation_table" />
            <th><span :class="`text-${history.result ? 'success' : 'danger'}`">{{ history.result ? 'Positive' : 'Negative' }}</span></th>
            <th>
              <a
                :href="$router.resolve({name: 'researcher.administration.promotion-history.see-evaluation', query: { user: history.applicant.id, convo: history.promotion.id, actAs: $route.query.actAs}}).href"
                target="_blank"
              ><i
                class="me-50"
                data-feather="eye"
              /></a>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      histories: 'promotionHistory/items',
      user: 'users/user',

    }),
  },
  async mounted() {
    this.$store.dispatch('promotionHistory/filter', { researcher: this.user })

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
}
</script>
